import React from "react";

export const QuoteIcon = ({ quoteColor = "#EDC034" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="quote-icon"
    width="40"
    height="30"
    fill={quoteColor}
  >
    <path
      d="M 24.295394,26.863758 C 19.200505,21.145204 22.710131,11.190578 26.951762,7.3424212 C 30.566689,4.0628338 36.556731,1.3813774 37.121428,2.3183024 C 38.019154,3.8077781 34.48577,4.865211 31.829231,7.2558366 C 28.512946,10.842074 27.399362,12.454202 31.988064,14.477295 C 36.906646,16.697086 37.001109,18.868305 37.159152,21.630467 C 37.55703,28.584322 29.269122,31.837485 24.295394,26.863758 z "
      id="path1873"
    />
    <path
      d="M 5.1231491,26.764559 C 0.028260593,21.046005 3.5378861,11.091379 7.7795171,7.2432227 C 11.394445,3.9636353 17.384487,1.2821789 17.949184,2.2191039 C 18.84691,3.7085796 15.313526,4.7660125 12.656987,7.1566381 C 9.3407014,10.742875 8.2271171,12.355003 12.81582,14.378096 C 17.734402,16.597887 17.828865,18.769106 17.986908,21.531268 C 18.384786,28.485123 10.096878,31.738286 5.1231491,26.764559 z "
      id="path1875"
    />
  </svg>
);

export default QuoteIcon;
